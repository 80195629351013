<template>
  <div :class="$style.home">
    <div :class="$style.list">
      <div :class="$style.title">订购列表</div>
      <a-table :columns="columns" :loading="loading" :data-source="data.list" :pagination="false" row-key="id">
        <template #cover="item">
          <div :class="$style.version">
            <div :class="$style.cover">
              <img :src="item.cover || item.icon" />
              <div :class="$style.ver">{{ item.type_name }}</div>
            </div>
            <div :class="$style.name">
              {{ item.name }}
            </div>
          </div>
        </template>
      </a-table>
    </div>
    <div :class="$style.num">
      <div :class="$style.title">费用说明</div>
      <div :class="$style.box">
        <div :class="$style.item">
          <div :class="$style.left">合计金额</div>
          <div :class="$style.right">￥{{ data.order_amount }}</div>
        </div>
        <div :class="$style.item">
          <div :class="$style.left">优惠金额</div>
          <div :class="$style.right">-￥{{ data.discount_amount }}</div>
        </div>
        <div :class="$style.item">
          <div :class="$style.left">抵扣剩余时长费用</div>
          <div :class="$style.right">-￥{{ data.version_deduction_amount }}</div>
        </div>
        <div :class="$style.item">
          <div :class="$style.left">应付金额</div>
          <div :class="$style.right">￥{{ data.actual_amount }}</div>
        </div>
      </div>
    </div>
    <div :class="$style.btns">
      <div :class="$style.btn">
        <BjButton type="primary" :disabled="!agree" @click="onSubmit()">
          <i class="left ri-bank-card-line" />
          立即支付
        </BjButton>
      </div>
      <div :class="$style.agree">
        <a-checkbox v-model="agree" />
        我同意
        <span class="primary">《北鲸软件产品使用协议》</span>
        和
        <span class="primary">《用户隐私协议》</span>
        和
        <span class="primary">《北鲸saas产品订购协议》</span>
      </div>
    </div>
  </div>
</template>

<script>
import { centerService } from '@/service'

const service = new centerService()

export default {
  name: 'Home',
  data() {
    return {
      loading: false,
      data: {
        list: [],
      },
      agree: false,
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '',
          width: 50,
        },
        {
          title: '名称',
          width: 300,
          scopedSlots: {
            customRender: 'cover',
          },
        },
        { title: '明细', dataIndex: 'properties_title' },
        {
          title: '金额',
          dataIndex: 'price',
        },
      ]
    },
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      try {
        const { data } = await service.getPre()
        this.data = data
        this.data.list.map(item => {
          if (!item.children.length) {
            delete item.children
          }
        })
      } catch (e) {
        this.$router.push({
          name: 'dashboard',
        })
      }
    },
    async onSubmit() {
      try {
        const { data } = await service.create()
        this.$router.push({
          name: 'commonCashier',
          query: {
            order_id: data.order_id,
            order_type: data.order_type,
          },
        })
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.home {
  .title {
    font-size: 16px;
    color: #000;
    margin-bottom: 20px;
  }

  .list {
    background: #fff;
    padding: 20px;
  }

  .num {
    background: #fff;
    margin-top: 20px;
    height: 196px;
    padding: 20px;
  }

  .btns {
    background: #fff;
    margin-top: 20px;
    height: 114px;
    padding: 20px;

    .btn {
      text-align: right;
      margin-bottom: 20px;
    }

    .agree {
      text-align: right;
    }
  }

  .error {
    color: @error-color;
  }

  .box {
    width: 400px;
    float: right;
    .item {
      display: flex;
      margin-bottom: 10px;
    }
    .left {
      flex: 1;
      text-align: right;
      color: #000;
    }

    .right {
      text-align: right;
      width: 130px;
      color: @error-color;
    }
  }

  .agree {
    font-size: 12px;
    color: #bbb;
  }

  .version {
    display: flex;
    align-items: center;

    .cover {
      width: 50px;
      height: 50px;
      border-radius: 6px;
      position: relative;
      margin-right: 10px;
      .ver {
        position: absolute;
        width: 50px;
        height: 18px;
        bottom: 0;
        left: 0;
        border-radius: 0px 0px 6px 6px;
        background: @primary-color;
        color: #fff;
        text-align: center;
      }

      img {
        width: 50px;
        height: 50px;
        border-radius: 6px;
      }
    }

    .name {
      font-size: 14px;
      color: #000;
    }
  }
}
</style>
